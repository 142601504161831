
const getSeasonFilter = (season) => {
  const moment = require('moment');
  const one_month_ago = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(30, 'days');
  const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const filter_month = { startDate: one_month_ago, endDate: today };
  const today_month = today.month();
  const today_date = today.date();
  switch (season) {
    case 'winter':
      const winter_has_passed = today_month > 0 || (today_month === 0 && today_date > 15);
      const winter_start_date = winter_has_passed ? moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'years').month(11).date(15) : moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(2, 'years').month(11).date(15);
      const winter_end_date = winter_start_date.clone().add(1, 'months');
      return { startDate: winter_start_date, endDate: winter_end_date };
    case 'spring':
      const spring_has_passed = today_month > 3 || (today_month === 3 && today_date > 15);
      const spring_start_date = spring_has_passed ? moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).month(2).date(15) : moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'years').month(2).date(15);
      const spring_end_date = spring_start_date.clone().add(1, 'months');
      return { startDate: spring_start_date, endDate: spring_end_date };
    case 'summer':
      const summer_has_passed = today_month > 6 || (today_month === 6 && today_date > 15);
      const summer_start_date = summer_has_passed ? moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).month(5).date(15) : moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'years').month(5).date(15);
      const summer_end_date = summer_start_date.clone().add(1, 'months');
      return { startDate: summer_start_date, endDate: summer_end_date };
    case 'autumn':
      const autumn_has_passed = today_month > 9 || (today_month === 9 && today_date > 15);
      const autumn_start_date = autumn_has_passed ? moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).month(8).date(15) : moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'years').month(8).date(15);
      const autumn_end_date = autumn_start_date.clone().add(1, 'months');
      return { startDate: autumn_start_date, endDate: autumn_end_date };
    default:
      return filter_month;
  }
};

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function getFormattedDate(date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), 0, 0);
}

const getGroupedData = (timeseries) => {
  let data_result = []
  // timeseries = timeseries.filter(item => item.value_nature_brut + item.value_nature_reel + item.value_nature_corrige >= 0.95*item.value)
  // data_result = timeseries.map(function (item) { return { "time": getFormattedDate(new Date(item.time)), "value": item.value } });
  data_result = timeseries.map(function (item) { return { "time": getFormattedDate(new Date(item.time)), "value": item.value, "estimated_value": (item.value_nature_brut + item.value_nature_reel + item.value_nature_corrige) < 0.95*item.value } });
  var result = [];
  data_result.reduce(function (res, value) {
    if (!res[value.time]) {
      res[value.time] = { time: value.time, value: 0, estimated_value: value.estimated_value };
      result.push(res[value.time])
    }
    res[value.time].value += value.value
    res[value.time].estimated_value = res[value.time].estimated_value || value.estimated_value
    return res;
  }, {});
  return result
}

function getDayMondayFirst(date) {
  var day = date.getDay();
  return day === 0 ? 6 : day - 1;
}

const getGroupedDataByDayOfWeek = (groupedData) => {
  let days = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim']
  let data = []
  data = groupedData.map(function (item) { return { "day": getDayMondayFirst((new Date(item.time))) + ' - ' + (new Date(item.time)).getHours(), "value": item.value, "estimated_value": item.estimated_value } });
  var reduced = [];
  data.reduce(function (res, value) {
    if (!res[value.day]) {
      res[value.day] = { day: value.day, value: 0, count: 0, estimated_value: value.estimated_value };
      reduced.push(res[value.day])
    }
    res[value.day].value += value.value;
    res[value.day].estimated_value = res[value.day].estimated_value || value.estimated_value;
    res[value.day].count += 1;
    return res;
  }, {});

  reduced.sort(function (a, b) {
    return a.day.split(' - ')[0] - b.day.split(' - ')[0];
  });

  let result = []
  result = reduced.map(function (k) {
    return [
      parseInt(k.day.split(' - ')[1]),
      days[k.day.split(' - ')[0]],
      k.value / k.count,
      k.estimated_value
    ]
  })
  return result
}

const getGroupedDataByDay = (groupedData, filter) => {
  let data = []
  const options = { weekday: 'short', year: "2-digit", month: 'short', day: 'numeric' };

  if (filter) {
    let start_date = filter.startDate
    let end_date = filter.endDate
    let current_date = start_date
    while (current_date < end_date) {
      current_date = new Date(current_date)
      let date = capitalize(current_date.toLocaleDateString(localStorage.getItem('language'), options))
      if (!groupedData.some(item => item.time.getDate() === current_date.getDate())) {
        data.push([0, date, null, false])
      }
      else {
        let items = groupedData.filter(item => item.time.getDate() === current_date.getDate() && item.time.getMonth() === current_date.getMonth() && item.time.getFullYear() === current_date.getFullYear())
        items.forEach(item => {
          data.push([(new Date(item.time)).getHours(), date, item.value, item.estimated_value])
        })
      }
      current_date.setDate(current_date.getDate() + 1)
    }
  }
  else {
    data = groupedData.map(function (item) { return [(new Date(item.time)).getHours(), capitalize((new Date(item.time)).toLocaleDateString(localStorage.getItem('language'), options)), item.value, item.estimated_value] });
  }

  return data
}

const calcul_heatmap_data = (data, groupBy, filter) => {
  let groupedData = getGroupedData(data)
  let result = groupBy === "day_of_week" ? getGroupedDataByDayOfWeek(groupedData) : getGroupedDataByDay(groupedData, filter)
  return result
}

function convertHeatmapDataToLines(data, unit) {
  let data_rows = [["Jour"], ["Heure"], ["Consommation ("+unit+")"]]
  data.forEach(element => {
      data_rows[0].push(element[1])
      data_rows[1].push(element[0])
      data_rows[2].push(element[2])
  })
  return data_rows
}

export { getSeasonFilter, calcul_heatmap_data, convertHeatmapDataToLines }






