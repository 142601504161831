const getGroup = (month, groupByTrimester) => {
  if (groupByTrimester) {
    return Math.floor(month / 3) + 1
  }
  return month + 1
}

const transformIndex = (timeseries) => {
  if (timeseries.length === 0) {
    return []
  }
  let index_types = Object.keys(timeseries[0]).filter(key => key.startsWith("value_season_"))
  let energy = {}
  index_types.forEach(index_type => {
    for (let i = 0; i < timeseries.length-1; i++) {
      if (timeseries[i]["time"] in energy) {
        energy[timeseries[i]["time"]] += timeseries[i+1][index_type] - timeseries[i][index_type]
      }
      else {
        energy[timeseries[i]["time"]] = timeseries[i+1][index_type] - timeseries[i][index_type] 
      }
    }
  })
  let energy_list = []
  Object.keys(energy).forEach(key => {
    energy_list.push({ time: key, value: energy[key] })
  })
  return energy_list
}


const getBarSeries = (timeseries, source) => {
  let groupedTimeseries = {}
  let serie = timeseries;
  if (source === "index") {
    serie = transformIndex(timeseries)
  }
  serie.forEach(element => {
    let date = new Date(element.time)
    let group = getGroup(date.getMonth(), false)
    let year = date.getFullYear()
    let key = year
    let value = parseFloat(element.value)
    if (value || value === 0) {
      if (groupedTimeseries[key] === undefined) {
        groupedTimeseries[key] = {}
      }
      if (groupedTimeseries[key][group] === undefined) {
        groupedTimeseries[key][group] = value
      }
      else {
        groupedTimeseries[key][group] += value
      }
    }
  })
  groupedTimeseries = Object.keys(groupedTimeseries).sort().reverse().reduce(
    (obj, key) => {
      obj[key] = groupedTimeseries[key];
      return obj;
    },
    {}
  );

  let series = []
  Object.keys(groupedTimeseries).forEach(year => {
    Object.keys(groupedTimeseries[year]).forEach(month => {
      let date = new Date(year + '-' + month)
      series.push([date, Math.round(groupedTimeseries[year][month] * 100) / 100])
    })
  })

  return series
}

const getTableSeries = (timeseries, groupByTrimester, source) => {
  let groupedTimeseries = {}
  let serie = timeseries;
  if (source === "index") {
    serie = transformIndex(timeseries)
  }
  serie.forEach(element => {
    let date = new Date(element.time)
    let group = getGroup(date.getMonth(), groupByTrimester)
    let year = date.getFullYear()
    let key = year
    let value = parseFloat(element.value)
    if (value || value === 0) {
      if (groupedTimeseries[key] === undefined) {
        groupedTimeseries[key] = {}
      }
      if (groupedTimeseries[key][group] === undefined) {
        groupedTimeseries[key][group] = value
      }
      else {
        groupedTimeseries[key][group] += value
      }
    }
  })
  groupedTimeseries = Object.keys(groupedTimeseries).sort().reverse().reduce(
    (obj, key) => {
      obj[key] = groupedTimeseries[key];
      return obj;
    },
    {}
  );

  if (groupByTrimester) {
    let years = Object.keys(groupedTimeseries)
    if (years.length > 5) {
      years = years.slice(years.length - 5, years.length)
      let newGroupedTimeseries = {}
      years.forEach(year => {
        newGroupedTimeseries[year] = groupedTimeseries[year]
      })
      groupedTimeseries = newGroupedTimeseries
    }
  }

  return groupedTimeseries
}

const getSerieAggFluidDestination = (timeseries, groupByTrimester) => {
  let groupedTimeseries = {}
  timeseries.forEach(element => {
    let date = new Date(element.time)
    let group = getGroup(date.getMonth(), groupByTrimester)
    let year = date.getFullYear()
    let key = year
    Object.keys(element.aggFluidDestination).forEach(fluid => {
      if (element.aggFluidDestination[fluid]) {
        Object.keys(element.aggFluidDestination[fluid]).forEach(destination => {
          let value = element.aggFluidDestination[fluid][destination]?.value
          if (value) {
            if (groupedTimeseries[fluid + '_' + destination] === undefined) {
              groupedTimeseries[fluid + '_' + destination] = {}
            }
            if (groupedTimeseries[fluid + '_' + destination][key] === undefined) {
              groupedTimeseries[fluid + '_' + destination][key] = {}
            }
            if (groupedTimeseries[fluid + '_' + destination][key][group] === undefined) {
              groupedTimeseries[fluid + '_' + destination][key][group] = value
            }
            else {
              groupedTimeseries[fluid + '_' + destination][key][group] += value
            }
          }
        })
      }
    })
  })
  Object.keys(groupedTimeseries).forEach(serie => {
    let serieToDelete = true
    Object.keys(groupedTimeseries[serie]).forEach(year => {
      Object.keys(groupedTimeseries[serie][year]).forEach(month => {
        if (groupedTimeseries[serie][year][month] !== 0) {
          serieToDelete = false
        }
      })
    })
    if (serieToDelete) {
      delete groupedTimeseries[serie]
    }
  })
  return groupedTimeseries
}



export { getTableSeries, getSerieAggFluidDestination, getBarSeries}