// Lib
import React, {useState} from "react";
import { TextField, useTranslate, FunctionField, FilterForm, useGetList, CreateButton, ExportButton } from "react-admin";
import { useNavigate } from 'react-router-dom';
import globalUseStyles from "../../styles/globalCustomStyles";
import { Chip } from '@mui/material';
import { utils, writeFile } from 'xlsx';

// Components
import UsersIcon from "../../assets/UsersIcon";
import CustomList from "../aegeon/CustomList";
import SynchronizeButton from "../Buttons/SynchronizeButton";

const UsersList = (props) => {
  const t = useTranslate();
  const globalClasses = globalUseStyles();

  const navigate = useNavigate();

  const roleChoices = [
    { id: 'admin', name: t('users.general_information.profiles.admin') },
    { id: 'property_owner', name: t('users.general_information.profiles.property_owner') },
    { id: 'property_manager', name: t('users.general_information.profiles.property_manager') },
    { id: 'property_tenant', name: t('users.general_information.profiles.property_tenant') },
  ]

  const statusChoices = [
    { id: 'true', name: t('users.general_information.enabled') },
    { id: 'false', name: t('users.general_information.disabled') },
  ]

  const filters = [
    // <AutocompleteInput label={t("users.general_information.profile")} source="role" choices={roleChoices}/>,
    // <AutocompleteInput label={t("users.general_information.status")} source="enabled" choices={statusChoices}/>,
  ];

  const { data: sites } = useGetList(
    'sites'
  ); 

  const { data: devices } = useGetList(
    'devices'
  );

  const fields = [
    <TextField label={t('users.general_information.name')} source="username" key="username" />,
    <TextField label={t("users.general_information.email")} source="email" key="email" />,
    <FunctionField
      label={t("users.general_information.profile")}
      render={record => t(`users.general_information.profiles.${record.role}`)}
      key="role"
    />,
    <FunctionField
      label={t("users.general_information.status")}
      render={record => record.enabled ? t("users.general_information.enabled") : t("users.general_information.disabled")}
      key="enabled"
    />,
    <FunctionField source="perimeter.sites" label={t("users.perimeter.title")} 
    render={(record) => {
      return (
      <>
      <div className={globalClasses.userListPerimeter}>
      {record?.role === 'property_owner' && record?.excludedPerimeter?.sites && record?.excludedPerimeter?.sites.length > 0 ?
      <>
        {`${t("users.perimeter.sites")} ${t("users.perimeter.excluded")} : `}
        {record?.excludedPerimeter?.sites?.map((element, index) =>
          <Chip key={record?.id + ' ' + index} size="small" label={sites?.find(site => site.id === element)?.airtable?.code} />)
        }
      </> :
      record?.perimeter?.sites && record?.perimeter?.sites.length > 0 ? 
      <>
        {t("users.perimeter.sites") + " : "}
        {record?.perimeter?.sites.map((element, index) =>
          <Chip key={record?.id + ' ' + index} size="small" label={sites?.find(site => site.id === element)?.airtable?.code} />)
        }
      </>
      : ""}
      </div>
      <div className={globalClasses.userListPerimeter}>
      {record?.role === 'property_owner' && record?.excludedPerimeter?.devices && record?.excludedPerimeter?.devices.length > 0 ?
      <>
        {`${t("users.perimeter.devices")} ${t("users.perimeter.excluded")} : `}
        {record?.excludedPerimeter?.devices?.map((element, index) =>
          <Chip key={record?.id + ' ' + index} size="small" label={devices?.find(site => site.id === element)?.name} />)
        }
      </> :
      record?.perimeter?.devices && record?.perimeter?.devices.length > 0 ?
        <>
        {t("users.perimeter.devices") + " : "}
        {record?.perimeter?.devices.map((element, index) =>
          <Chip key={record?.id + ' ' + index} size="small" label={devices?.find(device => device.id === element)?.name} />)
        }
        </>
      : record?.timeConstraintPerimeter?.devices && record?.timeConstraintPerimeter?.devices.length > 0 ?
      <>
        {t("users.perimeter.devicesWithTimeConstraints") + " : "}
        {record?.timeConstraintPerimeter?.devices.map((element, index) =>
          <Chip key={record?.id + ' ' + index} size="small" label={devices?.find(device => device.id === element.id)?.name} />)
        }
      </>
      : ""}
      </div>
      </>  
    )
    }} key="perimeter.sites" />,
  ];

  const export_row_to_excel = (data) => {
      let sheets_data = [{ sheetName: '', data: data }];
      var wb = utils.book_new();
      if (sheets_data.length > 0) {
        sheets_data.forEach((sheet) => {
          var ws = utils.aoa_to_sheet(sheet.data);
          let nb_of_cols = sheet.data[0].length;
          let wscols = [];
          for (let i = 0; i < nb_of_cols; i++) {
            wscols.push({ wch: 20 });
          }
          ws['!cols'] = wscols;
          utils.book_append_sheet(wb, ws, sheet.sheetName);
        });
        writeFile(wb, 'users_export.xlsx');
      }
  }

  const deleteTypename = (obj) => {
    for (let key in obj) {
      if (key === "__typename") {
        delete obj[key];
      } else if (typeof obj[key] === "object") {
        deleteTypename(obj[key]);
      }
    }
  }

  const splitExcelColumn = (data, column, headers) => {
    let excel_limit_size = 32767;
    if (data[column] && data[column].length > excel_limit_size) {
      let new_column = column + "2";
      data[new_column] = data[column].substring(excel_limit_size);
      data[column] = data[column].substring(0, excel_limit_size);
      if (!headers.includes(new_column)) {
        let index = headers.indexOf(column);
        headers.splice(index + 1, 0, new_column);
      }
    }
  }

  const getDateFromDateTime = (datetime) => {
    if (!datetime) {
      return null;
    }
    let date = new Date(datetime);
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - (offset*60*1000))
    return date.toISOString().split('T')[0];
  }

  const CustomListToolbar = () => {
    const customExporter = users => {
        let headers = ['username', 'email', 'role', 'enabled', 'perimeterSitesCodes', 'perimeterDevicesCodes', 'timeConstraintPerimeterDevices', 'excludedPerimeterSites', 'excludedPerimeterDevices'];
        const usersForExport = users.map(user => {
          user['perimeterSitesCodes'] = user?.perimeter?.sites?.map(site => sites?.find(s => s.id === site)?.airtable?.code).join(',');
          user['perimeterDevicesCodes'] = user?.perimeter?.devices?.map(device => devices?.find(d => d.id === device)?.name).join(',');
          splitExcelColumn(user, 'perimeterDevicesCodes', headers);
          user['excludedPerimeterSites'] = user?.excludedPerimeter?.sites?.map(site => sites?.find(s => s.id === site)?.airtable?.code).join(',');
          user['excludedPerimeterDevices'] = user?.excludedPerimeter?.devices?.map(device => devices?.find(d => d.id === device)?.name).join(',');
          user['timeConstraintPerimeterDevices'] = user?.timeConstraintPerimeter?.devices?.map(device => {
            let deviceName = devices?.find(d => d.id === device.id)?.name;
            if (!deviceName) {
              return '';
            }
            let periods = device.periods.map(period => {
              period.startDate = getDateFromDateTime(period.startDate);
              period.endDate = getDateFromDateTime(period.endDate);
              return `${deviceName},${period.startDate},${period.endDate}`;
            });
            if (periods.length === 0) {
              periods.push(`${deviceName},null,null`);
            }
            return periods.join(';');
          }).filter(device => device).join(';');

          splitExcelColumn(user, 'timeConstraintPerimeterDevices', headers);
          user['enabled'] = user['enabled'] ? 'true' : 'false';
          deleteTypename(user);
          delete user?.perimeter;
          delete user?.timeConstraintPerimeter;
          return user;
        });
        let data = [headers, ...usersForExport.map(user => headers.map(header => user?.[header]))];
        export_row_to_excel(data);
    };


    return (
      <div className={globalClasses.listActions}>
        <FilterForm filters={filters} />
        <div className={globalClasses.listButtons}>
          <CreateButton resource="users" onClick={() => navigate("/users/create")} className={globalClasses.customButton} style={{ marginLeft: "15px" }}/>
          <SynchronizeButton resource={"users"} />
          <ExportButton resource={"users"} exporter={customExporter} className={globalClasses.customButton} style={{ marginLeft: "15px" }}
          disabled={!sites || !devices} />
        </div>
      </div>
  )};


  return (
    <CustomList filters={filters} Icon={UsersIcon} resource="users" fields={fields} CustomListToolbar={CustomListToolbar}/>
  );
};

export default UsersList;