import { useContext, useMemo } from 'react';

import TimeseriesDataProviderContext from './TimeseriesDataProviderContext';
// import TimeseriesDataProvider from './TimeseriesDataProvider';

const useTimeseriesDataProvider = () => {
    const timeseriesDataProvider = useContext(TimeseriesDataProviderContext);

    // const logoutIfAccessDenied = useLogoutIfAccessDenied();

    const timeseriesDataProviderProxy = useMemo(() => {
        return new Proxy(timeseriesDataProvider, {
            get: (target, name) => {
                if (typeof name === 'symbol' || name === 'then') {
                    return;
                }
                return (...args) => {
                    const type = name.toString();

                    if (typeof timeseriesDataProvider[type] !== 'function') {
                        throw new Error(
                            `Unknown timeseriesDataProvider function: ${type}`
                        );
                    }

                    try {
                        return timeseriesDataProvider[type]
                            .apply(timeseriesDataProvider, args)
                            .then(response => {
                                if (process.env.NODE_ENV !== 'production') {
                                    console.log(response);
                                    console.log(type);
                                    // validateResponseFormat(response, type);

                                }
                                return response;
                            })
                            .catch(error => {
                                if (process.env.NODE_ENV !== 'production') {
                                    console.error(error);
                                }
                                return Promise.resolve(false);
                            });
                    } catch (e) {
                        if (process.env.NODE_ENV !== 'production') {
                            console.error(e);
                        }
                        throw new Error(
                            'The timeseriesDataProvider threw an error. It should return a rejected Promise instead.'
                        );
                    }
                };
            },
        });
        // }, [timeseriesDataProvider, logoutIfAccessDenied]);
    }, [timeseriesDataProvider]);

    return timeseriesDataProviderProxy;
};

export default useTimeseriesDataProvider;