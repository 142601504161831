/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onGetDevicePropertyTimeseriesAsyncResult = /* GraphQL */ `
  subscription OnGetDevicePropertyTimeseriesAsyncResult($token: ID!) {
    onGetDevicePropertyTimeseriesAsyncResult(token: $token) {
      id
      status
      timeseries {
        grid_name
        likelihood_index_season_base
        likelihood_index_season_hc
        likelihood_index_season_hcb
        likelihood_index_season_hce
        likelihood_index_season_hch
        likelihood_index_season_hp
        likelihood_index_season_hpb
        likelihood_index_season_hpe
        likelihood_index_season_hph
        likelihood_index_season_ja
        likelihood_index_season_p
        likelihood_index_season_pointe
        monotone_cumulative_time_in_min
        monotone_end_date
        monotone_execution_time
        monotone_start_date
        nature
        read_reason
        reason
        sampling_rate_in_min
        season_class_dict
        source
        time
        value
        value_nature_absence
        value_nature_actual
        value_nature_brut
        value_nature_corrected
        value_nature_corrige
        value_nature_coupure_secteur
        value_nature_coupure_secteur_courte
        value_nature_debut_de_coupure_secteur
        value_nature_esti
        value_nature_estimated
        value_nature_estime
        value_nature_fin_de_coupure_secteur
        value_nature_importe_manuellement_par_le_metier_enedis
        value_nature_no_data
        value_nature_puissance_reconstituee_et_coupure_secteur
        value_nature_reel
        value_nature_regu
        value_season_base
        value_season_hc
        value_season_hcb
        value_season_hce
        value_season_hch
        value_season_hp
        value_season_hpb
        value_season_hpe
        value_season_hph
        value_season_ja
        value_season_p
        value_season_pointe
        value_source_airtable_manual_bills
        value_source_airtable_third_party_active_power_in_kwh
        value_source_airtable_third_party_energy
        value_source_enedis_active_power_daily_in_kwh
        value_source_enedis_billed_energy
        __typename
      }
      token
      unit
      __typename
    }
  }
`;
export const streamedAnalysis = /* GraphQL */ `
  subscription StreamedAnalysis(
    $analysisClass: AnalysisClassEnum!
    $analysisType: AnalysisTypeEnum!
    $id: ID!
  ) {
    streamedAnalysis(
      analysisClass: $analysisClass
      analysisType: $analysisType
      id: $id
    ) {
      analysisClass
      analysisType
      assistent_prompt
      id
      status
      system_prompt
      user_prompt
      __typename
    }
  }
`;
