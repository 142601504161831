// Libs
import React, { useEffect, useState } from "react";
import { useNotify, useRecordContext, useTranslate } from "react-admin";
import ExportButton from "../../../components/Buttons/ExportButton";
import { export_row_to_excel } from '../../../components/aegeon/helper';
import useGetTimeseriesProvider from '../../aegeon/timeseries/useGetTimeseriesProvider';

const moment = require('moment');

const DeviceExportButton = (props) => {
  const { onClick } = props;

  const t = useTranslate();
  const record = useRecordContext();
  const notify = useNotify();

  const [token, setToken] = useState(undefined);
  const [asyncData, setAsyncData] = useState({ timeseries: [], status: "IDLE" });
  const [completeAsyncData, setCompleteAsyncData] = useState(false);


  const startDateTenyears = moment().set({ year: moment().year() - 10, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  const endDateToday = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const filterTenYears = { startDate: startDateTenyears, endDate: endDateToday };

  const [exporting, setExporting] = useState(false);

  // ****************************************************************************************************
  // Prepare All Calls to the Export API
  // ****************************************************************************************************
  let fastResponseProperties = [
    {
      name: "fusioned_energy",
      aggregation: { period: 'MONTH', operation: 'SUM' },
      export_schema: { header: ["pdl", "timestamp", "valeur", "unité"] }
    },
    {
      name: "manual_bills",
      aggregation: undefined,
      export_schema: { header: ["pdl", "timestamp", "valeur", "unité"] }
    },
    {
      name: "third_party_energy",
      aggregation: undefined,
      export_schema: { header: ["pdl", "timestamp", "valeur", "unité"] }
    },
    {
      name: "billed_energy",
      aggregation: { period: 'MONTH', operation: 'SUM' },
      export_schema: { header: ["pdl", "timestamp", "valeur", "unité"] }
    },
    {
      name: "informative_energy",
      aggregation: undefined,
      export_schema: { header: ["pdl", "timestamp", "valeur", "unité"] }
    },
    // ADDED
    {
      name: "active_power",
      aggregation: undefined,
      filter: { startDate: moment('2022-12-31'), endDate: moment('2023-03-01') },
      export_schema: { header: ["pdl", "timestamp", "valeur", "unité", "pas de temps in minutes", "nature"] }
    },
    {
      name: "active_power",
      aggregation: undefined,
      filter: { startDate: moment('2023-03-01'), endDate: moment('2023-05-01') },
      export_schema: { header: ["pdl", "timestamp", "valeur", "unité", "pas de temps in minutes", "nature"] }
    },
    {
      name: "active_power",
      aggregation: undefined,
      filter: { startDate: moment('2023-05-01'), endDate: moment('2023-07-01') },
      export_schema: { header: ["pdl", "timestamp", "valeur", "unité", "pas de temps in minutes", "nature"] }
    },
    {
      name: "active_power",
      aggregation: undefined,
      filter: { startDate: moment('2023-07-01'), endDate: moment('2023-09-01') },
      export_schema: { header: ["pdl", "timestamp", "valeur", "unité", "pas de temps in minutes", "nature"] }
    },
    {
      name: "active_power",
      aggregation: undefined,
      filter: { startDate: moment('2023-09-01'), endDate: moment('2023-11-01') },
      export_schema: { header: ["pdl", "timestamp", "valeur", "unité", "pas de temps in minutes", "nature"] }
    },
    {
      name: "active_power",
      aggregation: undefined,
      filter: { startDate: moment('2023-11-01'), endDate: moment('2024-01-01') },
      export_schema: { header: ["pdl", "timestamp", "valeur", "unité", "pas de temps in minutes", "nature"] }
    },
    // ____ADDED
  ];

  // COMMENTED
  let slowResponseProperties = [
    // {
    //   name: "active_power",
    //   aggregation: undefined,
    //   export_schema: { header: ["pdl", "timestamp", "valeur", "unité", "pas de temps in minutes", "nature"] }
    // }
  ];

  fastResponseProperties = fastResponseProperties.map((property) => {
    property.id = record.properties.find((element) => element.name === property.name)?.id;
    property.unit = record.properties.find((element) => element.name === property.name)?.unit;
    return property;
  });

  slowResponseProperties = slowResponseProperties.map((property) => {
    property.id = record.properties.find((element) => element.name === property.name)?.id;
    property.unit = record.properties.find((element) => element.name === property.name)?.unit;
    return property;
  });

  const onStreamedData = (provider, value) => {
    let asyncDataChunk = asyncData;
    console.log("receiving data");
    // Append the new data to the existing data
    asyncDataChunk.timeseries.push.apply(asyncDataChunk.timeseries, value.data.onGetDevicePropertyTimeseriesAsyncResult.timeseries);
    // Update the status
    asyncDataChunk.status = value.data.onGetDevicePropertyTimeseriesAsyncResult.status;
    // Save the new data
    setAsyncData(asyncDataChunk);

    if (value.data.onGetDevicePropertyTimeseriesAsyncResult.status === "COMPLETE") {
      console.log("complete data");
      setCompleteAsyncData(true);
      provider.close();
    }
  };

  const billed_energy_device = ["grdfMeter", "enedisMeter"].includes(record?.deviceType);

  // All calls to the API - This is done like this to avoid calling the API when not needed and due to hook constaints
  fastResponseProperties[0].dataProvider = useGetTimeseriesProvider('devices', {
    deviceId: record.id,
    propertyId: fastResponseProperties[0].id,
    filter: filterTenYears,
    aggregation: fastResponseProperties[0].aggregation,
  }, { enabled: exporting && fastResponseProperties[0].id !== undefined });

  fastResponseProperties[1].dataProvider = useGetTimeseriesProvider('devices', {
    deviceId: record.id,
    propertyId: fastResponseProperties[1].id,
    filter: filterTenYears,
    aggregation: fastResponseProperties[1].aggregation,
  }, { enabled: exporting && fastResponseProperties[1].id !== undefined });

  fastResponseProperties[2].dataProvider = useGetTimeseriesProvider('devices', {
    deviceId: record.id,
    propertyId: fastResponseProperties[2].id,
    filter: filterTenYears,
    aggregation: fastResponseProperties[2].aggregation,
  }, { enabled: exporting && fastResponseProperties[2].id !== undefined });

  fastResponseProperties[3].dataProvider = useGetTimeseriesProvider('devices', {
    deviceId: record.id,
    propertyId: fastResponseProperties[3].id,
    filter: filterTenYears,
    aggregation: fastResponseProperties[3].aggregation,
  }, { enabled: exporting && fastResponseProperties[3].id !== undefined && billed_energy_device });

  fastResponseProperties[4].dataProvider = useGetTimeseriesProvider('devices', {
    deviceId: record.id,
    propertyId: fastResponseProperties[4].id,
    filter: filterTenYears,
    aggregation: fastResponseProperties[4].aggregation,
  }, { enabled: exporting && fastResponseProperties[4].id !== undefined });

  // ADDED___________
  fastResponseProperties[5].dataProvider = useGetTimeseriesProvider('devices', {
    deviceId: record.id,
    propertyId: fastResponseProperties[5].id,
    filter: fastResponseProperties[5].filter,
    aggregation: fastResponseProperties[5].aggregation,
  }, { enabled: exporting && fastResponseProperties[5].id !== undefined });

  fastResponseProperties[6].dataProvider = useGetTimeseriesProvider('devices', {
    deviceId: record.id,
    propertyId: fastResponseProperties[6].id,
    filter: fastResponseProperties[6].filter,
    aggregation: fastResponseProperties[6].aggregation,
  }, { enabled: exporting && fastResponseProperties[6].id !== undefined });

  fastResponseProperties[7].dataProvider = useGetTimeseriesProvider('devices', {
    deviceId: record.id,
    propertyId: fastResponseProperties[7].id,
    filter: fastResponseProperties[7].filter,
    aggregation: fastResponseProperties[7].aggregation,
  }, { enabled: exporting && fastResponseProperties[7].id !== undefined });

  fastResponseProperties[8].dataProvider = useGetTimeseriesProvider('devices', {
    deviceId: record.id,
    propertyId: fastResponseProperties[8].id,
    filter: fastResponseProperties[8].filter,
    aggregation: fastResponseProperties[8].aggregation,
  }, { enabled: exporting && fastResponseProperties[8].id !== undefined });

  fastResponseProperties[9].dataProvider = useGetTimeseriesProvider('devices', {
    deviceId: record.id,
    propertyId: fastResponseProperties[9].id,
    filter: fastResponseProperties[9].filter,
    aggregation: fastResponseProperties[9].aggregation,
  }, { enabled: exporting && fastResponseProperties[9].id !== undefined });

  fastResponseProperties[10].dataProvider = useGetTimeseriesProvider('devices', {
    deviceId: record.id,
    propertyId: fastResponseProperties[10].id,
    filter: fastResponseProperties[10].filter,
    aggregation: fastResponseProperties[10].aggregation,
  }, { enabled: exporting && fastResponseProperties[10].id !== undefined });
  // _____ADDED

  // fastResponseProperties[5].dataProvider = useGetTimeseriesProvider('devices', {
  //   deviceId: record.id,
  //   propertyId: fastResponseProperties[5].id,
  //   filter: filterTenYears,
  //   aggregation: fastResponseProperties[5].aggregation,
  // }, { enabled: exporting && fastResponseProperties[5].id !== undefined });

  // COMMENTED
  // Slow response
  // slowResponseProperties[0].dataProvider = useQueryProvider('devices', {
  //   query_name: "getDevicePropertyTimeseriesAsync",
  //   id: record.id,
  //   params: {
  //     deviceId: record.id,
  //     propertyId: slowResponseProperties[0].id,
  //     timestamp: { between: [startDateTenyears, endDateToday] },
  //     aggregation: slowResponseProperties[0].aggregation,
  //   }
  // }, { enabled: exporting && slowResponseProperties[0].id !== undefined && token === undefined });

  // slowResponseProperties[0].subscriptionProvider = useSubscriptionProvider({
  //   subscriptionName: "onGetDevicePropertyTimeseriesAsyncResult",
  //   queryParams: { token: token !== undefined ? token : "" }
  // },
  //   onStreamedData,
  //   { enabled: exporting && token !== undefined }
  // );
  // _____COMMENTED

  // Enable a spinner when exporting
  useEffect(() => {
  }, [exporting,]);

  // Handle Success
  useEffect(() => {
    let status = [];
    let data_list = [];

    // Loading data
    fastResponseProperties.forEach((property) => {
      switch (property.dataProvider.status) {
        case "success":
          if (property.dataProvider.timeseries !== undefined) {
            status.push("success");

            let data = property.dataProvider;

            // ADDED_____
            if (property.name === "active_power") {
              data.timeseries = data.timeseries.map((element) => {
                const europeParisDate = element.time; //already converted to Europe/Paris in the TimeseriesDataProvider
                return { ...element, time: europeParisDate };
              });
              let start_month = data.timeseries[0]?.time
              data_list.push({
                serie: "AP" + start_month,
                unit: "k" + property.unit + "h",
                data: data.timeseries.map((element) => {
                  return {
                    time: element.time,
                    value: Math.round((element.value * element.sampling_rate_in_min / 60 / 1000) * 1000) / 1000
                  }
                })
              });
            }
            else {
              // _____ADDED
              data.timeseries = data.timeseries.map((element) => {
                const europeParisDate = moment(element.time).format('YYYY-MM-DD');
                return { ...element, time: europeParisDate };
              });
              data_list.push({
                serie: property.name,
                unit: property.unit,
                data: data.timeseries.map((element) => {
                  return {
                    time: element.time,
                    value: element.value
                  }
                })
              });
            }
          }
          else {
            status.push("failed");
          }
          break;
        case "loading":
          status.push("loading");
          break;
        case "idle":
          status.push("idle");
          break;
        default:
          break;
      }
    });
    // setCompleteSyncData(status.map((element) => element === "success"));

    slowResponseProperties.forEach((property, index) => {
      switch (property.dataProvider.status) {
        case "success":
          if (property.dataProvider.data?.token && property.dataProvider.data.token !== token && exporting === true) {
            console.log("Token received" + property.dataProvider.data.token);
            if (token !== undefined) {
              property.subscriptionProvider.subscribe("onGetDevicePropertyTimeseriesAsyncResult", { token: property.dataProvider.data.token }, onStreamedData);
            }
            setToken(property.dataProvider.data.token);
            status.push("loading");
          }

          else if (asyncData.status === "COMPLETE" && completeAsyncData === true) {
            status.push("success");
            let data = asyncData;

            // here timeseries are in local timezone and already ordered
            data.timeseries = asyncData.timeseries.map((element) => {
              const europeParisDate = element.time;
              return { ...element, time: europeParisDate };
            });
            data_list.push({
              serie: property.name,
              unit: property.unit,
              data: asyncData.timeseries.map((element) => {
                return {
                  time: element.time,
                  value: element.value,
                  nature: element.nature,
                  sampling_rate_in_min: element.sampling_rate_in_min
                }
              })
            });
          }
          else {
            status.push("loading");
          }
          break;
        case "loading":
          status.push("loading");
          break;
        case "idle":
          status.push("idle");
          break;
        default:
          break;
      }
    });

    // 10 years table
    const headers_10years = ["2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022", "2023"];
    const data_10years = headers_10years.map((header) => {
      return {
        time: header,
        value: record.airtable[`consumption_${header}_10years_in_kwh_pcs`] ? record.airtable[`consumption_${header}_10years_in_kwh_pcs`] : ""
      }
    });
    // check if value in data_10years is not empty or "" to push to data_list
    if (!data_10years.every((element) => element.value === "")) {
      data_list.push({
        serie: "ten_years",
        unit: "kWh PCS",
        data: data_10years
      });
    }

    // Handle results of the API calls
    if (status.includes("failed")) {
      if (exporting === true) {
        setExporting(false);
        notify(t('devices.exports.error'), { type: "error" });
      }
    }
    // All elements are idle
    else if (status.every((element) => element === "idle")) {
      if (exporting === true) {
        setExporting(false);
        notify(t('devices.exports.warning'), { type: "warning" });
      }
    }
    // All elements in status are success
    else if (status.every((element) => element === "success" || element === "idle")) {
      if (exporting === true) {
        const success = exportExcel(data_list);
        if (success) {
          notify(t('devices.exports.success'), { type: "success" });
        } else {
          notify(t('devices.exports.warning'), { type: "warning" });
        }
        setExporting(false);
      }
    }
  }, [fastResponseProperties, exporting, slowResponseProperties, completeAsyncData]);

  // Export data to excel
  const exportExcel = (data) => {
    var sheets_data = [];
    if (data !== null) {

      // ADDED
      let data_no_active_power = data.filter((item) => !item.serie.includes("AP"));
      // _____ADDED
      data_no_active_power.forEach((item) => {
        if (item.data.length > 0) {
          const sheetName = t(`devices.sources.${item.serie}`).slice(0, 30);
          let rows = [];
          if (item.serie === 'active_power') {
            rows = [["pdl", "timestamp", "valeur", "unité", "pas de temps in minutes", "nature"]]
            item.data.forEach((element) => {
              rows.push([record.name,
              element.time,
              element.value,
              item.unit,
              element.sampling_rate_in_min,
              element.nature]);
            });
          } else {
            rows = [["pdl", "timestamp", "valeur", "unité"]]
            item.data.forEach((element) => {
              rows.push([record.name,
              element.time,
              element.value,
              item.unit]);
            });
          }
          sheets_data.push({ sheetName: sheetName, data: rows });
        }
      });

      // ADDED
      let active_power_data = data.filter((item) => item.serie.includes("AP"));
      if (active_power_data.length > 0) {
        let rows = [["pdl", "time", "valeur", "unité"]]
        active_power_data.sort((a, b) => {
          return new Date(a.serie) - new Date(b.serie);
        });
        active_power_data.forEach((item) => {
          let data = item.data.filter((element) => { return moment(element.time).isSameOrAfter('2023-01-01') && moment(element.time).isBefore('2024-01-01') });
          data.forEach((element) => {
            rows.push([record.name,
            element.time,
            element.value,
            item.unit]);
          });
        });
        sheets_data.push({ sheetName: "Données détaillées 2023", data: rows });
      }
      // _____ADDED

      if (sheets_data.length > 0) {
        export_row_to_excel(sheets_data, `${t('devices.export.prefix')}${record.name}.xlsx`);
        return true;
      }
      return false;
    };
  };

  const handleExport = () => {
    onClick();
    setExporting(true);
    setCompleteAsyncData(false);
    if (asyncData.status === "COMPLETE") {
      setAsyncData({ timeseries: [], status: "IDLE" });
    }
    // FIXME: Clean AsyncData before exporting
    if (token !== undefined) {
      slowResponseProperties[0].dataProvider.refetch();
    }
  };

  return (
    <div>
      <ExportButton onClick={handleExport} disabled={exporting} />
    </div>
  );
};

export default DeviceExportButton;
