import { Typography } from '@mui/material';
import Switch from '@mui/material/Switch';
import ReactECharts from 'echarts-for-react';
import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import globalUseStyles from '../../../styles/globalCustomStyles';
import { adaptative_unit, convert_to_right_unit } from '../helper';
import { useQueryContext } from '../query/useQueryContext';
import { useTimeseriesContext } from '../timeseries/useTimeseriesContext';
import ChartLoading from "./ChartLoading";
import ChartNoData from "./ChartNoData";

const ChartIndex = (props) => {
  const {
    timeseries,
    isLoading,
    unit: propertyUnit,
  } = useTimeseriesContext(props);
  const [isChecked, setIsChecked] = useState(false);
  const [options, setOptions] = useState(null);

  const {
    data,
    isLoading: isLoadingQuery,
  } = useQueryContext(props);

  const t = useTranslate();
  const globalClasses = globalUseStyles();

  const {
    line,
    stacked,
    title,
    type,
    conversionButton,
    monthAgg,
    setDataCallback
  } = props;

  const colors = {
    "hce": "#FFBB10",
    "hpe": "#D8540B",
    "hch": "#80C3FF",
    "hph": "#6C74D8",
    "hc": "#FFBB10",
    "hp": "#D8540B",
    "hcb": "#FFBB10",
    "hpb": "#D8540B",
    "ja": "#07D60C",
    "pointe": "#E00707",
    "p": "#E00707",
    "default": "#71B9A1"
  }

  const switchButton = (event) => {
    setIsChecked(event.target.checked);
  };


  let default_units = {
    "index": "Wh",
    "index_substracted": "Wh",
    "energy": "Wh",
    "time_of_use": "s",
    "maximum_power": "VA",
    "overrun_duration": "s",
    "overrun_quadratic": "VA",
  }

  let unit = propertyUnit ? propertyUnit : default_units[type]

  const getMaxPowerSerie = (data, index_type) => {
    let value = null
    if (index_type) {
      value = data?.situationContractuelle?.structureTarifaire?.denivelePuissances?.classesTemporelles?.classeTemporelle?.find(
        (row) => row.code === index_type[0].toUpperCase()
      )?.puissance?.valeur
    }
    else {
      value = data?.situationContractuelle?.structureTarifaire?.puissanceSouscriteMax?.valeur
    }
    if (!value) {
      return null
    }
    return {
      name: "Puissance souscrite " + (index_type ? transformIndexName(index_type[1]) : "maximale"),
      type: 'line',
      showSymbol: false,
      data: timeseries?.map(element => [new Date(element.time), value * 1000]),
      itemStyle: {
        color: index_type ? colors[index_type[0]] : colors["default"]
      },
      lineStyle: {
        type: 'dashed'
      },
      label: {
        show: true,
        position: 'top',
        formatter: function (params) {
          return params.value[1] + " "
        }
      }
    }
  }


  const convertIndexToEnergy = (timeseries, index_type) => {
    let energy = []
    for (let i = 0; i < timeseries.length - 1; i++) {
      energy.push([new Date(timeseries[i].time), (timeseries[i + 1]["value_season_" + index_type] - timeseries[i]["value_season_" + index_type])])
    }
    return energy
  }

  const convertTimeOfUse = (timeseries, index_type) => {
    let energy = []
    for (let i = 0; i < timeseries.length - 1; i++) {
      if (timeseries[i + 1]["value_season_" + index_type] - timeseries[i]["value_season_" + index_type] < 0) {
        energy.push([new Date(timeseries[i].time), timeseries[i + 1]["value_season_" + index_type]])
      }
      else {
        energy.push([new Date(timeseries[i].time), (timeseries[i + 1]["value_season_" + index_type] - timeseries[i]["value_season_" + index_type])])
      }
    }
    let converted_energy = []
    for (let i = 0; i < energy.length; i++) {
      converted_energy.push([energy[i][0], Math.round(energy[i][1] * 100) / 100])
    }
    return converted_energy
  }

  const transformIndexName = (index_name) => {
    return index_name.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
  }

  const aggTimeseriesByMonth = (data) => {
    let data_agg = []
    let groupedData = {}
    data.forEach(element => {
      let date = new Date(element[0])
      let month = date.getMonth() + 1
      let key = date.getFullYear()
      let value = element[1]
      if (value || value === 0) {
        if (groupedData[key] === undefined) {
          groupedData[key] = {}
        }
        if (groupedData[key][month] === undefined) {
          groupedData[key][month] = [value, date]
        }
        else {
          groupedData[key][month][0] += value
        }
      }
    })
    Object.keys(groupedData).forEach((year) => {
      Object.keys(groupedData[year]).forEach((month) => {
        data_agg.push([new Date(year, month - 1), groupedData[year][month][0], groupedData[year][month][1]])
      })
    })
    return data_agg
  }

  const setSeries = (timeseries, isChecked, type, monthAgg) => {
    let series = []
    let index_types = timeseries && timeseries?.[0]?.season_class_dict?.split(",").map(element => element.split(":"))
    if (index_types) {
      series = index_types?.map((index_type) => {
        let serie_data = type === "index_substracted" ? convertIndexToEnergy(timeseries, index_type[0])
          : timeseries.map(element => [new Date(element.time), element["value_season_" + index_type[0]]])
        let new_data = (isChecked && !monthAgg) ? convertTimeOfUse(timeseries, index_type[0]) : isChecked ? aggTimeseriesByMonth(serie_data) : serie_data
        return {
          name: transformIndexName(index_type[1]),
          smooth: true,
          type: line && (!isChecked || !monthAgg) ? 'line' : 'bar',
          stack: stacked ? 'stack' : null,
          areaStyle: stacked ? {} : null,
          color: colors[index_type[0]],
          symbol: 'none',
          data: new_data,
        }
      })

      if (type === "maximum_power" && data) {
        index_types?.map((index_type) => {
          let serie = getMaxPowerSerie(data, index_type)
          serie && series.push(serie)
        }
        )
      }
    }
    else {
      let serie_data = timeseries.map(element => [new Date(element.time), element.value])
      let new_data = (isChecked && monthAgg) ? aggTimeseriesByMonth(serie_data) : serie_data
      series = [
        {
          name: t(`devices.${type}.title`),
          smooth: true,
          type: line && (!isChecked || !monthAgg) ? 'line' : 'bar',
          stack: stacked ? 'stack' : null,
          areaStyle: stacked ? {} : null,
          symbol: 'none',
          data: new_data,
          color: colors["default"]
        }
      ]

      if (type === "maximum_power" && data) {
        let serie = getMaxPowerSerie(data, null)
        serie && series.push(serie)
      }
    }

    return series
  }


  const setOption = (series, new_unit) => {
    return {
      title: {
        top: 10,
        left: 'center',
        text: title,
        textStyle: {
          fontSize: 18,
          fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"]
        },
      },
      tooltip: {
        trigger: 'axis',
        formatter: function (params) {
          let date = (isChecked && monthAgg) ? params[0].value[2] : params[0].value[0]
          let reading_time = `(Relevé à ${new Date(date).toLocaleTimeString(localStorage.getItem("language"), { hour: '2-digit', minute: '2-digit' })})`
          let name = (isChecked && monthAgg) ? new Date(params[0].value[0]).toLocaleDateString(localStorage.getItem("language"), { month: "2-digit", year: "numeric" }) + " " + reading_time + "<br>" : new Date(params[0].value[0]).toLocaleDateString(localStorage.getItem("language"), { day: "2-digit", month: "2-digit", year: "numeric" }) + " " + reading_time + "<br>"

          if (params.length > 1 && new_unit?.includes("h")) {
            let total = params.reduce((acc, element) => {
              return acc + element.value[1]
            }, 0)
            name += "Total : " + "<b>" + Math.round(total * 1000) / 1000 + " " + new_unit + "</b><br>"
          }
          params.forEach(element => {
            name += element.marker + " " + element.seriesName + " : " + "<b>" + (Math.round(element.value[1] * 1000) / 1000) + " " + new_unit + "</b><br>"
          });
          return name
        }
      },
      grid: {
        left: '5%',
        right: '3%',
        bottom: '20%',
        top: '5%',
        containLabel: true
      },
      xAxis: {
        type: 'time',
        splitNumber: series?.[0]?.data?.length > 5 ? 5 : series?.[0]?.data?.length,
        axisLabel: {
          formatter: function (value, index) {
            if (isChecked && monthAgg) {
              return new Date(value).toLocaleDateString(localStorage.getItem("language"), { month: "2-digit", year: "numeric" })
            }
            return new Date(value).toLocaleDateString(localStorage.getItem("language"), { day: "2-digit", month: "2-digit", year: "2-digit" })
          },
          fontSize: 10,
        },
      },
      yAxis: {
        type: 'value',
        name: t(`devices.${type}.title`) + " (" + new_unit + ")",
        nameLocation: 'middle',
        nameGap: 30,
        nameTextStyle: {
          fontSize: 10,
        },
        axisLabel: {
          fontSize: 10,
        },
      },
      series: series,
      legend: {
        data: series?.map(serie => serie.name),
        type: 'scroll',
        bottom: 20,
        textStyle: {
          fontSize: 10
        },
      },
      dataZoom: [
        {
          type: 'slider',
          bottom: 70,
          height: 20,
        }
      ],
    };
  }

  const convertData = (series, unit) => {
    let converted_data = [];
    for (let i = 0; i < series[0].data.length; i++) {
      let row = [series[0].data[i][0]];
      series.forEach(serie => {
        row.push(serie.data[i][1]);
      });
      converted_data.push(row);
    }
    let header = ["Date"];
    series.forEach(serie => {
      header.push(serie.name + " (" + unit + ")");
    });
    converted_data.unshift(header);
    return converted_data;
  }

  useEffect(() => {
    if (timeseries && timeseries.length > 0) {
      let series = setSeries(timeseries, isChecked, type, monthAgg)
      let maxValue = series?.reduce((max, serie) => {
        let serieMax = serie?.data?.reduce((max, data) => {
          return Math.max(max, data[1])
        }, 0)
        return Math.max(max, serieMax)
      }, 0)
      let new_unit = adaptative_unit(maxValue, unit)
      series = series?.map(serie => {
        return {
          ...serie,
          data: serie?.data?.map(data => {
            if (data.length === 2) {
              return [data[0], convert_to_right_unit(data[1], unit, new_unit)]
            } else {
              return [data[0], convert_to_right_unit(data[1], unit, new_unit), data[2]]
            }
          })
        }
      })
      series && setDataCallback && setDataCallback(convertData(series, new_unit), type)
      setOptions(setOption(series, new_unit));
    }
  }, [timeseries, isChecked, type, monthAgg]);



  return (
    <>
      {isLoading ?
        <ChartLoading />
        :
        timeseries && timeseries.length > 0 && options ?
          <div style={{ height: '100%', width: '100%' }}>
            {conversionButton ?
              <div className={globalClasses.SubDevicesAggregation}>
                <Typography variant="h12">
                  {t(`devices.${type}.button`)}
                </Typography>
                <Switch onChange={switchButton} />
              </div>
              :
              <div style={{ height: '48px' }}></div>
            }
            <ReactECharts
              option={options}
              notMerge={true}
              style={{ height: '90%', width: '100%' }}
            />
          </div>
          :
          <ChartNoData />
      }
    </>
  )
}

export default ChartIndex
