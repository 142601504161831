import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import InfoTooltipIcon from "../../../assets/InfoTooltipIcon";
import globalUseStyles from '../../../styles/globalCustomStyles';
import ExportButton from '../../Buttons/ExportButton';
import CustomTooltip from '../../aegeon/CustomTooltip';
import DetailsShow from '../../aegeon/cards/DetailsShow';
import ChartHeatmap from "../../aegeon/charts/ChartHeatmap";
import ChartLoading from '../../aegeon/charts/ChartLoading';
import { convertHeatmapDataToLines, getSeasonFilter } from '../../aegeon/compute_heatmap';
import { export_row_to_excel } from '../../aegeon/helper';
import QueryInfiniteField from "../../aegeon/query/QueryInfiniteField";
import HeatmapDataReceiver from "../CustomComponents/HeatmapDataReceiver";

const moment = require('moment');

const one_month_ago = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(30, 'days');
const end_date = moment().set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).subtract(2, 'days');
const filter_month = { startDate: one_month_ago, endDate: end_date };

const SiteHeatmapDetails = (props) => {
    const globalClasses = globalUseStyles();
    const t = useTranslate()
    const unit = "kWh";
    const title = t('sites.heatmap.title');

    const heatmaps_parameters = {
        day: {
            filter: filter_month,
            aggregation: { period: "HOUR", operation: "IN_KWH" },
            groupBy: "day_of_week",
            translate: "sites.heatmap.day",
            tooltip: true,
        },
        month: {
            filter: filter_month,
            aggregation: { period: "HOUR", operation: "IN_KWH" },
            groupBy: "month",
            translate: "sites.heatmap.month",
            tooltip: true,
        },
        last_winter: {
            filter: getSeasonFilter('winter'),
            aggregation: { period: "HOUR", operation: "IN_KWH" },
            groupBy: "month",
            translate: "sites.heatmap.last_winter",
            tooltip: true,
        },
        last_spring: {
            filter: getSeasonFilter('spring'),
            aggregation: { period: "HOUR", operation: "IN_KWH" },
            groupBy: "month",
            translate: "sites.heatmap.last_spring",
            tooltip: true,
        },
        last_summer: {
            filter: getSeasonFilter('summer'),
            aggregation: { period: "HOUR", operation: "IN_KWH" },
            groupBy: "month",
            translate: "sites.heatmap.last_summer",
            tooltip: true,
        },
        last_autumn: {
            filter: getSeasonFilter('autumn'),
            aggregation: { period: "HOUR", operation: "IN_KWH" },
            groupBy: "month",
            translate: "sites.heatmap.last_autumn",
            tooltip: true,
        },
    }

    var heatmaps_data = [];
    const [finalData, setFinalData] = useState({});
    const [minMax, setMinMax] = useState({ min: undefined, max: undefined });
    const [disabled, setDisabled] = useState(true);
    const addHeatmapData = (childData, key) => {
        if (!heatmaps_data.some((element) => Object.keys(element)[0] === key)) {
            heatmaps_data.push({ [key]: childData });
        }
        if (heatmaps_data.length === Object.keys(heatmaps_parameters).length) {
            var final_data = {};
            heatmaps_data.forEach((element) => {
                final_data = { ...final_data, ...element };
            });
            setFinalData(final_data);
            const min = Math.min(...Object.values(final_data).map((source) => Math.min(...source.map((value) => value[2]))));
            const max = Math.max(...Object.values(final_data).map((source) => Math.max(...source.map((value) => value[2]))));
            setMinMax({ min, max });
            setDisabled(false);
        }
    };


    const handleExport = (name) => {
        var sheets_data = [];
        if (finalData) {
            Object.keys(finalData).forEach((type) => {
                if (finalData[type].length > 0) {
                    const sheetName = t(`sites.heatmap.${type}.title`).slice(0, 30)
                    const dataSet = convertHeatmapDataToLines(finalData[type], unit);
                    sheets_data.push({ sheetName: sheetName, data: dataSet });
                }
            });
            export_row_to_excel(sheets_data, `${t('sites.exports.heatmap')}${name}.xlsx`);
        };
    }

    return (
        <DetailsShow title={title} tooltip={<ExportButton onClick={handleExport} disabled={disabled} />} resource="sites">
            <Card className={`${globalClasses.DetailCard} ${globalClasses.DetailCardTable}`}>
                <Grid2 container direction="row" justifyContent="center" alignItems="center"
                    spacing={5}
                    style={{ padding: '0 !important' }}
                >
                    {Object.keys(heatmaps_parameters).map((key) => (
                        <Grid2 item="true" small={12} medium={12} big={6} key={key}>
                            <div className={globalClasses.DetailCardTableTitle}>
                                <Typography variant="h8">{t(heatmaps_parameters[key]?.translate + '.title')}</Typography>
                                {heatmaps_parameters[key]?.tooltip && <CustomTooltip title={t(heatmaps_parameters[key]?.translate + '.tooltip_text')} icon={<InfoTooltipIcon />} />}
                            </div>
                            <div className={globalClasses.DetailCardTableSubtitle}>
                                {t(heatmaps_parameters[key]?.translate + '.subtitle') + " (du " + heatmaps_parameters[key]?.filter.startDate.format('DD/MM/YYYY') + " au " + heatmaps_parameters[key]?.filter.endDate.format('DD/MM/YYYY') + ")."}
                            </div>
                            <div className={globalClasses.DetailCardGraph}>
                                <QueryInfiniteField query_name="getSiteAggElecTimeseries" params={{ time: { between: [heatmaps_parameters[key]['filter']['startDate'], heatmaps_parameters[key]['filter']['endDate']] } }}>
                                    <>
                                        <HeatmapDataReceiver setHeatmapData={(childData) => addHeatmapData(childData, key)} groupBy={heatmaps_parameters[key]?.groupBy} filter={heatmaps_parameters[key]?.filter} />
                                        {Object.keys(finalData).length === Object.keys(heatmaps_parameters).length ?
                                            <ChartHeatmap data={finalData[key]} minMax={minMax} type={heatmaps_parameters[key]?.groupBy} unit={unit} isDetailed={true}
                                                filter={heatmaps_parameters[key]?.filter} /> :
                                            <ChartLoading />
                                        }
                                    </>
                                </QueryInfiniteField>
                            </div>
                        </Grid2>
                    ))}
                </Grid2>
            </Card>
        </DetailsShow>
    )
};

export default SiteHeatmapDetails;