import React, { useEffect, useRef, useState } from 'react';
import { useTranslate } from 'react-admin';
import ChartHorizontalBar from '../../aegeon/charts/ChartHorizontalBar';
import ChartLoading from '../../aegeon/charts/ChartLoading';
import { adaptative_unit, convert_timeseries_dj, convert_to_right_unit } from '../../aegeon/helper';
import { useQueryContext } from '../../aegeon/query/useQueryContext';

const ChartSiteHorizontalBar = (props) => {
    const {
        data,
        isLoading,
        hasNextPage,
    } = useQueryContext(props);

    const { isLoading: isLoadingDJ, type } = props

    const [isDone, setIsDone] = useState(false)

    const seriesRef = useRef([])
    const newUnitRef = useRef('')
    const djMinValueRef = useRef(0)
    const dataGroupedRef = useRef([])

    const dju = props.dju ? props.dju.timeseries : []
    const djr = props.djr ? props.djr.timeseries : []

    const t = useTranslate();
    const unit = "kWh"
    const unavailable_data = t('resources.global.unavailable_data')

    const filter_timeseries = (timeseries) => {
        let result = timeseries.map((timeserie) => {
            let new_timeserie = { ...timeserie }
            Object.keys(new_timeserie).forEach((key) => {
                if (new_timeserie[key] === null) {
                    delete new_timeserie[key]
                }
                else if (typeof new_timeserie[key] === 'object') {
                    new_timeserie[key] = filter_timeseries([new_timeserie[key]])[0]
                    if (Object.keys(new_timeserie[key]).length === 0) {
                        delete new_timeserie[key]
                    }
                }
            })
            return new_timeserie
        })
        return result
    }



    const getCategories = (type, timeseries) => {
        let categories = []
        if (type === 'aggFluidDestination') {
            timeseries.forEach((timeserie) => {
                Object.keys(timeserie.aggFluidDestination).forEach((fluid) => {
                    if (fluid !== "water" && fluid !== "sum" && fluid !== "__typename") {
                        Object.keys(timeserie.aggFluidDestination[fluid]).forEach((destination) => {
                            if (destination !== "sum" && destination !== "__typename" && !categories.includes(fluid + '.' + destination)) {
                                categories.push(fluid + '.' + destination)
                            }
                        })
                    }
                })
            }
            )
        }
        else if (type === 'aggFluid') {
            timeseries.forEach((timeserie) => {
                Object.keys(timeserie.aggFluid).forEach((fluid) => {
                    if (fluid !== "water" && fluid !== "sum" && fluid !== "__typename" && !categories.includes(fluid)) {
                        categories.push(fluid)
                    }
                })
            }
            )
        }
        else if (type === 'aggDestination') {
            timeseries.forEach((timeserie) => {
                Object.keys(timeserie.aggDestination).forEach((destination) => {
                    if (destination !== "sum" && destination !== "__typename" && !categories.includes(destination)) {
                        categories.push(destination)
                    }
                })
            }
            )
        }
        return categories
    }


    const groupByMonth = (timeseries, categories) => {
        let grouped = []
        let start_date = new Date(timeseries[0].time)
        let end_date = new Date()
        for (let year = start_date.getFullYear(); year <= end_date.getFullYear(); year++) {
            for (let i = 1; i <= 12; i++) {
                if (year === start_date.getFullYear() && i < start_date.getMonth() + 1) continue
                if (year === end_date.getFullYear() && i > end_date.getMonth() + 1) break
                let month = timeseries.filter(c => (new Date(c.time).getMonth() === (i - 1)) && new Date(c.time).getFullYear() === year)
                let monthData = {
                    month: i,
                    year: year,
                    data: {},
                }
                if (month.length === 0) {
                    grouped.push(monthData)
                    continue
                }
                else if (type === 'aggFluidDestination') {
                    for (const category of categories) {
                        let category_split = category.split('.')
                        let fluid = category_split[0]
                        let destination = category_split[1]
                        let values = month.map(item => item.aggFluidDestination?.[fluid]?.[destination]).filter(item => item !== undefined)
                        let value = values.length > 0 ? values.reduce((acc, curr) => acc + (curr && curr.value ? curr.value : 0), 0) : undefined
                        if (value || value === 0) {
                            if (monthData.data.aggFluidDestination) {
                                monthData.data.aggFluidDestination.total += value
                                if (monthData.data.aggFluidDestination[fluid]) {
                                    monthData.data.aggFluidDestination[fluid][destination] = value
                                } else {
                                    monthData.data.aggFluidDestination[fluid] = {
                                        [destination]: value
                                    }
                                }
                            }
                            else {
                                monthData.data.aggFluidDestination = {
                                    total: value,
                                    [fluid]: {
                                        [destination]: value
                                    }
                                }
                            }
                        }
                    }
                }
                else if (type === 'aggFluid') {
                    for (const category of categories) {
                        let fluid = category
                        let values = month.map(item => item.aggFluid?.[fluid]).filter(item => item !== undefined)
                        let value = values.length > 0 ? values.reduce((acc, curr) => acc + (curr && curr.value ? curr.value : 0), 0) : undefined
                        if (value || value === 0) {
                            if (monthData.data.aggFluid) {
                                monthData.data.aggFluid.total += value
                                monthData.data.aggFluid[fluid] = value
                            }
                            else {
                                monthData.data.aggFluid = {
                                    total: value,
                                    [fluid]: value
                                }
                            }
                        }
                    }
                }
                else {
                    for (const category of categories) {
                        let destination = category
                        let values = month.map(item => item.aggDestination?.[destination]).filter(item => item !== undefined)
                        let value = values.length > 0 ? values.reduce((acc, curr) => acc + (curr && curr.value ? curr.value : 0), 0) : undefined
                        if (value || value === 0) {
                            if (monthData.data.aggDestination) {
                                monthData.data.aggDestination.total += value
                                monthData.data.aggDestination[destination] = value
                            }
                            else {
                                monthData.data.aggDestination = {
                                    total: value,
                                    [destination]: value
                                }
                            }
                        }
                    }
                }
                grouped.push(monthData)
            }
        }
        return grouped
    }

    const colors = {
        electricity: '#4ecca2',
        gas: '#6c74d8',
        gaz: '#6c74d8',
        heat: "#5da4d9",
        cold: "#aad956",
        other: "#d95f48",
    }

    const transparency = {
        private: 'FF',
        shared: 'CC',
        private_vacant: '99',
        third_party: '77',
        private_vacant_shared: '55',
        deposited: '44',
        out_of_scope: '33',
    }

    const getSeries = (type, categories, data_grouped, new_unit) => {
        if (type === 'aggFluidDestination') {
            return categories.map(category => {
                let category_split = category.split('.')
                let fluid = category_split[0]
                let destination = category_split[1]
                const s = data_grouped.map(item => {
                    return convert_to_right_unit(Math.round(item.data?.aggFluidDestination?.[fluid]?.[destination]), unit, new_unit, unavailable_data)
                })
                const color = (fluid in colors ? colors[fluid] : colors.other) + (destination in transparency ? transparency[destination] : transparency.out_of_scope)
                let serie = {
                    name: t('sites.fluids.' + fluid) + ' ' + t('sites.destination.' + destination),
                    type: 'bar',
                    stack: 'one',
                    color: color,
                    data: s
                }
                return serie
            })
        } else if (type === 'aggFluid') {
            return categories.map(category => {
                let fluid = category
                const s = data_grouped.map(item => {
                    return convert_to_right_unit(Math.round(item.data?.aggFluid?.[fluid]), unit, new_unit, unavailable_data)
                })
                let serie = {
                    name: t('sites.fluids.' + fluid),
                    type: 'bar',
                    stack: 'one',
                    color: fluid in colors ? colors[fluid] : colors.other,
                    data: s
                }
                return serie
            })
        } else if (type === 'aggDestination') {
            return categories.map(category => {
                let destination = category
                const s = data_grouped.map(item => {
                    return convert_to_right_unit(Math.round(item.data?.aggDestination?.[destination]), unit, new_unit, unavailable_data)
                })
                let serie = {
                    name: t('sites.destination.' + destination),
                    type: 'bar',
                    stack: 'one',
                    color: '#6c74d8' + (destination in transparency ? transparency[destination] : transparency.out_of_scope),
                    data: s
                }
                return serie
            })
        }
    }

    const getDjuDjrSeries = (dju, djr, data_grouped) => {
        const dju_data = convert_timeseries_dj(dju, data_grouped)
        const djr_data = convert_timeseries_dj(djr, data_grouped)
        const dju_serie = {
            name: t('sites.histogram.dju'),
            type: 'line',
            color: '#D8540B',
            data: dju_data,
            yAxisIndex: 1
        }
        const djr_serie = {
            name: t('sites.histogram.djr'),
            type: 'line',
            color: '#FFBB10',
            data: djr_data,
            yAxisIndex: 1
        }
        return [dju_serie, djr_serie]
    }

    const calculate_minimum_value = (series) => {
        let min_value = 0
        if (series.length > 0) {
            min_value = series[0].data[0]
            for (let i = 0; i < series[0].data.length; i++) {
                let sum = 0
                for (let j = 0; j < series.length; j++) {
                    if (series[j].data[i] < 0) {
                        sum += series[j].data[i]
                    }
                }
                if (sum < min_value) {
                    min_value = sum
                }
            }
        }
        else {
            min_value = Math.min(...series.map(item => Math.min(...item.data)))
        }
        return min_value
    }

    const calculate_maximum_value = (series) => {
        let max_value = 0
        if (series.length > 0) {
            max_value = series[0].data[0]
            for (let i = 0; i < series[0].data.length; i++) {
                let sum = 0
                for (let j = 0; j < series.length; j++) {
                    if (series[j].data[i] > 0) {
                        sum += series[j].data[i]
                    }
                }
                if (sum > max_value) {
                    max_value = sum
                }
            }
        }
        else {
            max_value = Math.max(...series.map(item => Math.max(...item.data)))
        }
        return max_value
    }

    const calculate = (timeseries) => {
        let filtered_timeseries = filter_timeseries(timeseries)
        let categories = getCategories(type, filtered_timeseries)
        let data_grouped = groupByMonth(filtered_timeseries, categories)
        const maxValue = Math.max(...data_grouped.map(item => item.data[type]?.total ? item.data[type]?.total : 0))
        const new_unit = adaptative_unit(maxValue, unit)
        let series_data = getSeries(type, categories, data_grouped, new_unit).filter(item => item !== null)
        let dj_series = getDjuDjrSeries(dju, djr, data_grouped).filter(item => item !== null)
        let dj_min_value = Math.max(...dj_series.map(item => Math.max(...item.data))) / calculate_maximum_value(series_data) * calculate_minimum_value(series_data)
        let dj_series_with_unavailable_data = dj_series.map(item => {
            item.data = item.data.map(value => value === null ? unavailable_data : value)
            return item
        })
        let series = series_data.length > 0 ? [...series_data, ...dj_series_with_unavailable_data] : []
        seriesRef.current = series
        newUnitRef.current = new_unit
        djMinValueRef.current = dj_min_value
        dataGroupedRef.current = data_grouped
        setIsDone(true)
        return
    }

    useEffect(() => {
        if (!isDone && !isLoading && !hasNextPage && !isLoadingDJ && data && data.items) {
            if (data.items.length > 0) {
                calculate(data.items)
            } else {
                setIsDone(true)
            }
        }
    }, [isLoading, isLoadingDJ, hasNextPage, data, isDone])



    let yAxis = [
        {
            type: 'value',
            name: 'Consommation (' + newUnitRef.current + ')',
            nameLocation: 'middle',
            nameGap: 30,
            nameTextStyle: {
                fontSize: 10,
            },
            axisLabel: {
                fontSize: 10,
                formatter: function (value) {
                    return Math.round(value * 100) / 100
                }
            },
            min: djMinValueRef.current < 0 ? 'dataMin' : 0,
            max: djMinValueRef.current < 0 ? 'dataMax' : null,
        },
        {
            type: 'value',
            name: t('sites.histogram.dj'),
            nameLocation: 'middle',
            nameGap: 30,
            nameTextStyle: {
                fontSize: 10,
            },
            splitLine: {
                show: false
            },
            axisLabel: {
                fontSize: 10,
                formatter: function (value, index) {
                    if (value >= 0) {
                        return Math.round(value)
                    }
                }
            },
            min: djMinValueRef.current,
            max: 'dataMax',
        }
    ]

    return (
        !isDone ?
            <ChartLoading />
            :
            <ChartHorizontalBar
                series={seriesRef.current}
                new_unit={newUnitRef.current}
                isLoading={false}
                title={props.title}
                isData={seriesRef.current.length > 0}
                x_data={dataGroupedRef.current.map(item => t(`months.${item.month - 1}`) + ' ' + item.year)}
                data_total={dataGroupedRef.current.map(item => convert_to_right_unit(Math.round(item.data[type]?.total), unit, newUnitRef.current, unavailable_data))}
                hideLegend={props.hideLegend}
                yAxis={yAxis}
            />
    )
}

export default ChartSiteHorizontalBar;
