// Libs
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import React, { useContext, useEffect, useState } from "react";
import { useTranslate } from "react-admin";
// Material UI components
import { Typography } from "@mui/material";

// Custom Style
import { RecordContext } from '../../App';
import globalUseStyles from "../../styles/globalCustomStyles";
import BackButton from "../Buttons/BackButton";
import { allowedClickComponents, allowedShowComponents, useRolePermissions } from "../aegeon-rbac/AuthRolePermissions";
import { useQueryContext } from "../aegeon/query/useQueryContext";
import CardAnalysis from "./Cards/CardAnalysis";
import CardChartHeatmap from "./Cards/CardChartHeatmap";
import CardChartHorizontalBar from "./Cards/CardChartHorizontalBar";
import CardChartLine from "./Cards/CardChartLine";
import CardGauge from "./Cards/CardGauge";
import CardGeneralInformation from "./Cards/CardGeneralInformation";
import CardMonotone from "./Cards/CardMonotone";
import CardQuality from "./Cards/CardQuality";
import CardTable from "./Cards/CardTable";
import DeviceExportButton from './CustomButtons/DeviceExportButton';
import DeviceTypeIcon from './CustomComponents/DeviceTypeIcon';

const moment = require('moment');

const DeviceShowDetails = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();
  const [exporting, setExporting] = useState(false);
  const { record, setRecord } = useContext(RecordContext)

  const {
    data,
    isLoading: isLoadingData,
  } = useQueryContext(props);

  useEffect(() => {
    if (data && !isLoadingData) {
      setRecord(data)
    }
    else {
      setRecord(null)
    }
  }, [data, isLoadingData, record]);

  const endDate = moment().set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).subtract(2, 'days');
  const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const endDateLastDayOfLastMonth = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).set({ date: 1 }).subtract(1, 'days');

  const startDateBeginningOfTwoYearsAgo = moment().set({ year: moment().year() - 2, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  const startDateBeginningOfLastYear = moment().set({ year: moment().year() - 1, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  const startDateTenyears = moment().set({ year: moment().year() - 10, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  const startDateOneMonth = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(30, 'days');
  const startDateMonotone = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(7, 'days');
  const startDateSomeDays = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(15, 'days');

  const filterBeginningOfTwoYearsAgo = { startDate: startDateBeginningOfTwoYearsAgo, endDate: endDate };
  const filterBeginningOfLastYear = { startDate: startDateBeginningOfLastYear, endDate: endDate };
  const filterTenYears = { startDate: startDateTenyears, endDate: endDate };
  const filterOneMonth = { startDate: startDateOneMonth, endDate: endDate };
  const filterMonotone = { startDate: startDateMonotone, endDate: endDate };
  const filterSomeDays = { startDate: startDateSomeDays, endDate: endDate };
  const filterTenYearsToToday = { startDate: startDateTenyears, endDate: today };

  const formatDate = (date) => {
    const formatted_date = moment(date).format("MM-YYYY");
    if (formatted_date === "Invalid date") {
      return "";
    } else {
      return formatted_date;
    }
  };

  const property = record?.properties?.find((element) => element.name === "fusioned_energy");
  const start_date = property?.quality?.timeseries_start_date ? t('devices.page_subtitle.part2') + formatDate(property?.quality?.timeseries_start_date) : ""
  const quality = t('devices.page_subtitle.part3') + (record?.fusionedEnergyQuality ? record?.fusionedEnergyQuality : "N/A")

  const isEnedis = record?.deviceType === "enedisMeter";
  const isAutomatic = record?.airtable?.automatic_upload === "Automatique";

  const handleExport = () => {
    setExporting(true);
  };

  const permissionControlledComponents = {
    resource: "devices",
    elements: {
      show: {
        general_information: <CardGeneralInformation />,
        evolution: <CardGauge />,
        quality: <CardQuality filter={filterTenYearsToToday} source="fusioned_energy" />,
        histogram: <CardChartHorizontalBar filter={filterBeginningOfTwoYearsAgo} source="fusioned_energy" />,
        table: <CardTable filter={filterTenYears} source="fusioned_energy" />,
        analyse: <CardAnalysis />,
        heatmap: <CardChartHeatmap filter={filterOneMonth} source="active_power" enabled={isEnedis} />,
        monotone: <CardMonotone filter={filterMonotone} source="active_power_monotone" enabled={isEnedis} />,
        active_power: <CardChartLine filter={filterSomeDays} source="active_power" enabled={isEnedis} isAutomatic={isAutomatic} />
      },
      click: {
        export: <DeviceExportButton onClick={handleExport} disabled={exporting} />
      }
    }
  };
  const { isLoading, permissions } = useRolePermissions();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    record ?
      <div className={globalClasses.pageCard} >
        <BackButton record={record} />
        <div className={globalClasses.pageTitleContainer}>
          <div>
            <div className={globalClasses.pageTitle} >
              <Typography variant="h4">{t('devices.title')}{record.name}</Typography>
              <DeviceTypeIcon deviceType={record?.deviceType} />
            </div>
            <div className={globalClasses.pageSubTitle} >
              <Typography variant="h5" >{t('devices.page_subtitle.part1')}{start_date}{quality}</Typography>
            </div>
          </div>
          {allowedClickComponents(permissions, permissionControlledComponents)}
        </div>
        <div className={globalClasses.pageContent}>
          <Grid2
            className={globalClasses.grid} container spacing={4}>
            {allowedShowComponents(permissions, permissionControlledComponents)}
          </Grid2 >
        </div>
      </div >
      : <></>
  );
};

export default DeviceShowDetails;