import { utils, writeFile } from "xlsx";
import { indexOf } from "lodash";

const adaptative_unit = (maxValue, unit) => {  
  const new_unit =
    maxValue > 1000000000 && unit === "Wh" 
      ? unit.replace("Wh", "GWh")
      : maxValue > 1000000 && unit === "Wh"
      ? unit.replace("Wh", "MWh")
      : maxValue > 1000 && unit === "Wh"
      ? unit.replace("Wh", "kWh")
      : maxValue > 1000 && unit === "VA"
      ? unit.replace("VA", "kVA")
      : maxValue > 1000000 && unit === "W"
      ? unit.replace("W", "MW")
      : maxValue > 1000 && unit === "W"
      ? unit.replace("W", "kW")
      : maxValue > 1000000 && unit?.substring(0, 3) === "kWh"
      ? unit.replace("kWh", "GWh")
      : maxValue > 1000 && unit?.substring(0, 3) === "kWh"
      ? unit.replace("kWh", "MWh")
      : maxValue > 1000 && unit?.substring(0, 3) === "kg" 
      ? unit.replace("kg", "T")
      : maxValue > 3600 && unit==="s"
      ? unit.replace("s", "h")
      : unit;
  if (unit === "l") return "L";
  return new_unit;
};

const convert_to_right_unit = (value, previous_unit, new_unit, unavailable_data) => {
  if (!value && value!==0) {
    return unavailable_data;
  }
  else if (!previous_unit || !new_unit) {
    return value;
  }
  if (new_unit === "kVA" && previous_unit === "VA") {
    return value / 1000;
  } else if (new_unit === "kW" && previous_unit === "W") {
    return value / 1000;
  } else if (new_unit === "MW" && previous_unit === "W") {
    return value / 1000000;
  } else if (new_unit.substring(0, 3) === "GWh" && previous_unit === "Wh") {
    return value / 1000000000;
  } else if ((new_unit.substring(0, 3) === "GWh" && previous_unit?.substring(0, 3) === "kWh") || (new_unit.substring(0, 3) === "MWh" && previous_unit === "Wh")) {
    return value / 1000000;
  } else if ((new_unit.substring(0, 3) === "MWh" && previous_unit?.substring(0, 3) === "kWh") || (new_unit.substring(0, 3) === "kWh" && previous_unit === "Wh")) {
    return value / 1000;
  } else if (new_unit.substring(0, 1) === "T" && previous_unit?.substring(0, 2) === "kg") {
    return value / 1000;
  } else if (new_unit === "h" && previous_unit === "s") {
    return Math.round((value / 3600) * 100) / 100;
  } else {
    return value;
  }
};

const convert_timeseries_dj = (timeseries, data_grouped) => {
  if (!timeseries || timeseries.length === 0) {
      return []
  }
  let cleaned_timeseries = timeseries.map((timeserie) => {
      const date = new Date(timeserie.time);
      const local_date = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
      return {
          month: local_date.getMonth() + 1,
          year: local_date.getFullYear(),
          value: timeserie.value
      }
  })
  let result = []
  data_grouped.forEach((item) => {
      let month = item.month
      let year = item.year
      let month_year = cleaned_timeseries.find((timeserie) => {
          return timeserie.month === month && timeserie.year === year
      })
      if (month_year) {
          result.push(month_year.value)
      }
      else {
          result.push(null)
      }
  })
  return result
}

const export_row_to_excel = (sheets_data, file_name) => {
  var wb = utils.book_new();
  if (sheets_data.length > 0) {
    sheets_data.forEach((sheet) => {
      var ws = utils.aoa_to_sheet(sheet.data);
      let nb_of_cols = sheet.data[0].length;
      let wscols = [];
      for (let i = 0; i < nb_of_cols; i++) {
        wscols.push({ wch: 20 });
      }
      ws["!cols"] = wscols;
      utils.book_append_sheet(wb, ws, sheet.sheetName);
    });
    writeFile(wb, file_name);
  }
};

function convert_data_to_lines(data_lines, line_titles) {
  let lines = [];
  data_lines.forEach((data_line, index) => {
    let line = data_line;
    line.unshift(line_titles[index]);
    lines.push(line);
  });
  return lines;
}

function convert_data_for_chartline(data, unit, convert_to_kW) {
  let converted_data = [];
  data.forEach((element) => converted_data.push([element.time, element.value / (convert_to_kW ? 1000 : 1)]));
  converted_data.unshift(["Date", "Puissance (" + (convert_to_kW ? "k" : "") + unit + ")"]);
  return converted_data;
}

const convert_to_array = (value) => {
  if (value === null || value === undefined) {
    return "";
  }
  if (Array.isArray(value)) {
    return value;
  }
  value = value.replace(/ *, */g, ",");
  return value.split(",").map((element) => element.replace("[", "").replace("]", ""));
};

const value_with_unit = (value, unit) => {
  if (value === null || value === undefined) {
    return null;
  } else {
    value = Math.round(parseFloat(value) * 100) / 100;
    return value.toLocaleString(localStorage.getItem("language")) + " " + unit;
  }
};

const convert_string_boolean_to_string = (value) => {
  if (value?.includes("true")) {
    return "Oui";
  } else if (value?.includes("false")) {
    return "Non";
  } else {
    return null;
  }
};

const convert_boolean_to_string_yes_no = (value) => {
  if (value === null || value === undefined) {
    return null;
  } else if (value) {
    return "Oui";
  } else {
    return "Non";
  }
};

const convert_boolean_to_string = (value) => {
  if (value) {
    return "Oui";
  } else {
    return "Non";
  }
};

const convert_single_select_to_string = (value) => {
  if (typeof value === "string") {
    let val = value?.toLowerCase();
    if (val?.includes("oui")) {
      return "Oui";
    } else if (val?.includes("non")) {
      return "Non";
    } else {
      return null;
    }
  }
};

const convert_boolean_to_string_yes = (value) => {
  if (value) {
    return "Oui";
  } else {
    return null;
  }
};

const convert_array_to_string = (array) => {
  if (array === null || array === undefined) {
    return "";
  }
  if (Array.isArray(array)) {
    array = array.filter((item) => item !== null);
    return array.join(", ");
  }
  return array;
};

const convert_array_string_to_string = (string) => {
  if (string === null || string === undefined) {
    return null;
  } else {
    let list = string.split(",");
    return list.join(", ");
  }
};

const convert_array_to_multiple_lines_string = (value) => {
  if (value === null || value === undefined) {
    return null;
  }
  if (Array.isArray(value)) {
    return value.join("\n ");
  }
  return value;
};

const destinationList = [
  "À IDENTIFIER",
  "COMMUNES",
  "COMPTEUR DÉPOSÉ",
  "HORS PÉRIMÈTRE",
  "PRIVATIVES",
  "PRIVATIVES VACANTES",
  "PRIVATIVES VACANTES ET COMMUNES",
  "TIERCES",
];

const fluidTypeList = [
  "EAU",
  "ELEC",
  "GAZ",
  "HEAT",
  "COLD",
  "FOD",
  "PROPANE",
  "WOOD",
  "BUTANE",
  "COAL",
  "OTHER FOSSIL FUEL",
  "ERREUR",
];

const automaticUploadList = ["Automatique", "Factures ou Index"];

const getDataMarkPoint = (point, values, colors, position, isDetailedCard) => {
  const index = indexOf(Object.keys(values).reverse(), point);
  return {
    coord: [values[point], index],
    value: point,
    label: {
      show: true,
      color: point === "G" ? "white" : "black",
      fontSize: isDetailedCard ? 16 : 14,
      position: position,
    },
    itemStyle: {
      color: colors[point],
    },
  };
};

const round_number = (value) => {
  if (value === null || value === undefined) {
    return null;
  }
  return Math.round(value);
};

const formatDateString = (dateString) => {
  if (dateString === null || dateString === undefined) {
    return "";
  }
  const date = new Date(dateString);
  return date.toLocaleDateString(localStorage.getItem("language"));
};

const convert_list_to_contacts = (list) => {
  if (list === null || list === undefined) {
    return null;
  } else {
    return list
      .map((elem) => {
        let value = elem?.name;
        if (elem.email !== null && elem.email !== undefined) {
          value += " (" + elem.email+ ")";
        }
        return value;
      })
      .join("\n");
  }
};

const convert_group_to_string = (group_list) => {
  let group_name = group_list.find((group) => !group.includes("admin") && !group.includes("property_"));
  if (group_name === null || group_name === undefined) {
    return null;
  }
  var splitted_str = group_name.split("_");
  var new_str = "";
  splitted_str.forEach((word, index) => {
    if (index === 0) {
      new_str += word.charAt(0).toUpperCase() + word.slice(1);
    } else {
      if (word === "de" || word === "du" || word === "la" || word === "le") {
        new_str += " " + word;
      } else {
        new_str += " " + word.charAt(0).toUpperCase() + word.slice(1);
      }
    }
  });
  return new_str;
};


const translateKey = (t, key) => {
  let new_key = key.replace("_suffixes","").replace('airtable.',"").replace('functional_entities.',"").replace()
  new_key = new_key.replace(/.*\.name/, "contact")
  let translated = t("resources.search.indexes."+new_key)  
  return "<b>"+translated+"</b>"
}

const translateValue = (t, value) => {
  if (value.includes("device")) {
    return value.replace("device",t("resources.search.indexes.devices"))
  }
  else if (value.includes("site")) {
    return value.replace("site",t("resources.search.indexes.sites"))
  }
  return value
}

const filter_and_sort_devices = (devices) => {
  // Filter unknownMeter and ERREUR devices, sort by fluid type, destination and quality
  let devices_filtered = devices?.filter((device) => (device.deviceType !== "unknownMeter" && device.airtable?.fluid_type !== "ERREUR"))

  devices_filtered?.sort((a, b) => {
      let fluid_electricity_pound_a = a.airtable?.fluid_type === "ELEC" ? 1 : 0;
      let fluid_electricity_pound_b = b.airtable?.fluid_type === "ELEC" ? 1 : 0;
      let fluid_water_pound_a = a.airtable?.fluid_type === "WATER" ? 1 : 0;
      let fluid_water_pound_b = b.airtable?.fluid_type === "WATER" ? 1 : 0;
      if (fluid_electricity_pound_a > fluid_electricity_pound_b) {
          return -1;
      }
      else if (fluid_electricity_pound_a < fluid_electricity_pound_b) {
          return 1;
      }
      if (fluid_water_pound_a > fluid_water_pound_b) {
          return 1;
      }
      else if (fluid_water_pound_a < fluid_water_pound_b) {
          return -1;
      }
      let destination_a = a.airtable?.destination ? a.airtable?.destination : "";
      let destination_b = b.airtable?.destination ? b.airtable?.destination : "";
      if (destination_a < destination_b) {
          return -1;
      }
      else if (destination_a > destination_b) {
          return 1;
      }
      let quality_a = a.fusionedEnergyQuality ? a.fusionedEnergyQuality : "";
      let quality_b = b.fusionedEnergyQuality ? b.fusionedEnergyQuality : "";
      if (quality_a < quality_b) {
          return -1;
      }
      else if (quality_a > quality_b) {
          return 1;
      }
      return 0;
  })
  return devices_filtered
}

const findAlgoliaMatchsKeys = (elem, highlightResult, type, t) => {
  let matchedItems = []
  if (elem === null || elem === undefined) {
    return matchedItems
  }
  Object.keys(elem).forEach((key) => {
    if (Array.isArray(elem[key])) {
      elem[key].forEach((item) => {
        let new_item = {}
        new_item[key] = item
        matchedItems = matchedItems.concat(findAlgoliaMatchsKeys(new_item, highlightResult, type, t))
      })
    }
    else if (typeof elem[key] === 'object' && !elem[key].hasOwnProperty("matchLevel")) {
      const new_elem = {}
      Object.keys(elem[key]).forEach((key2) => {
        new_elem[key + '.' + key2] = elem[key][key2]
      }
      )
      matchedItems = matchedItems.concat(findAlgoliaMatchsKeys(new_elem, highlightResult, type, t))
    }
    else {
      if (elem[key].matchLevel === "full") {
        let keyString = ""
        if (key.includes("suffixes")) {
          const previous_key = key.replace("airtable_","airtable.").replace("_suffixes","")
          const result = previous_key.includes('.') ? highlightResult[previous_key.split('.')[0]][previous_key.split('.')[1]] : highlightResult[previous_key]
          const highlightedStr = elem[key].matchedWords?.[0]
          let value = result?.value
          if (type === "item") {
            value = value?.replace("__aa-highlight__","").replace("__\/aa-highlight__","").replace(highlightedStr,"__aa-highlight__"+highlightedStr+"__/aa-highlight__")
          } else if (type === "card") {
            value = value?.replace("<ais-highlight-0000000000>","").replace("</ais-highlight-0000000000>","").replace(highlightedStr,'<ais-highlight-0000000000>'+highlightedStr+'</ais-highlight-0000000000>')
          }
          keyString = translateKey(t, previous_key) + ' : '+ translateValue(t, value)
        }
        else {
          keyString = translateKey(t, key) + ' : ' + translateValue(t, elem[key].value)
        }
        matchedItems.push(keyString.replace(/__aa-highlight__/g, "<b>").replace(/__\/aa-highlight__/g, "</b>").replace('<ais-highlight-0000000000>',"<b>").replace('</ais-highlight-0000000000>',"</b>"))
      }
    }
  }
  )
  matchedItems = [...new Set(matchedItems)]
  return matchedItems
}




export {
  adaptative_unit,
  convert_to_right_unit,
  export_row_to_excel,
  convert_data_to_lines,
  convert_data_for_chartline,
  convert_to_array,
  value_with_unit,
  destinationList,
  fluidTypeList,
  automaticUploadList,
  getDataMarkPoint,
  convert_string_boolean_to_string,
  convert_boolean_to_string_yes_no,
  convert_boolean_to_string_yes,
  convert_array_to_string,
  formatDateString,
  round_number,
  convert_single_select_to_string,
  convert_array_to_multiple_lines_string,
  convert_list_to_contacts,
  convert_array_string_to_string,
  convert_group_to_string,
  findAlgoliaMatchsKeys,
  convert_timeseries_dj,
  convert_boolean_to_string,
  filter_and_sort_devices
};
