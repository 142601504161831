import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import ChartHorizontalBar from '../../aegeon/charts/ChartHorizontalBar';
import { adaptative_unit, convert_timeseries_dj, convert_to_right_unit } from '../../aegeon/helper';
import { useTimeseriesContext } from '../../aegeon/timeseries/useTimeseriesContext';

const ChartDeviceHorizontalBarDJ = (props) => {
    const {
        timeseries,
        isLoading,
    } = useTimeseriesContext(props);
    const unit = props.unit

    const { isLoadingDJ } = props

    const [isDone, setIsDone] = useState(false)
    const [series, setSeries] = useState([])
    const [new_unit, setNewUnit] = useState('')
    const [dj_min_value, setDjMinValue] = useState(0)
    const [data_grouped, setDataGrouped] = useState([])

    const dju = props.dju ? props.dju.timeseries : []
    const djr = props.djr ? props.djr.timeseries : []

    const t = useTranslate();
    const unavailable_data = t('resources.global.unavailable_data')

    const groupByMonth = (timeseries) => {
        if (!timeseries || timeseries.length === 0) {
            return []
        }
        let startDate = new Date(timeseries[0].time)
        let endDate = new Date()
        let grouped = []
        let date = startDate
        while (date <= endDate) {
            let year = date.getFullYear()
            let month = date.getMonth() + 1
            let value = timeseries.find((item) => {
                let item_date = new Date(item.time)
                return item_date.getFullYear() === year && item_date.getMonth() + 1 === month
            })
            if (!value) {
                value = undefined
            }
            else {
                value = value.value
            }
            grouped.push({
                month: month,
                year: year,
                value: value
            })
            date.setMonth(date.getMonth() + 1)
        }
        return grouped
    }

    const getSeries = (data_grouped, new_unit) => {
        let series = []
        if (data_grouped.length > 0) {
            let serie = {
                name: "Consommation",
                type: 'bar',
                stack: 'one',
                color: "#4ecca2",
                data: data_grouped.map(item => convert_to_right_unit(Math.round(item.value), unit, new_unit, unavailable_data))
            }
            series.push(serie)
        }
        return series
    }

    const getDjuDjrSeries = (dju, djr, data_grouped) => {
        const dju_data = convert_timeseries_dj(dju, data_grouped)
        const djr_data = convert_timeseries_dj(djr, data_grouped)
        const dju_serie = {
            name: t('sites.histogram.dju'),
            type: 'line',
            color: '#D8540B',
            data: dju_data,
            yAxisIndex: 1
        }
        const djr_serie = {
            name: t('sites.histogram.djr'),
            type: 'line',
            color: '#FFBB10',
            data: djr_data,
            yAxisIndex: 1
        }
        return [dju_serie, djr_serie]
    }

    const calculate_minimum_value = (series) => {
        let min_value = 0
        if (series.length > 0) {
            min_value = series[0].data[0]
            for (let i = 0; i < series[0].data.length; i++) {
                let sum = 0
                for (let j = 0; j < series.length; j++) {
                    if (series[j].data[i] < 0) {
                        sum += series[j].data[i]
                    }
                }
                if (sum < min_value) {
                    min_value = sum
                }
            }
        }
        else {
            min_value = Math.min(...series.map(item => Math.min(...item.data)))
        }
        return min_value
    }


    const calculate_maximum_value = (series) => {
        let max_value = 0
        if (series.length > 0) {
            max_value = series[0].data[0]
            for (let i = 0; i < series[0].data.length; i++) {
                let sum = 0
                for (let j = 0; j < series.length; j++) {
                    if (series[j].data[i] > 0) {
                        sum += series[j].data[i]
                    }
                }
                if (sum > max_value) {
                    max_value = sum
                }
            }
        }
        else {
            max_value = Math.max(...series.map(item => Math.max(...item.data)))
        }
        return max_value
    }

    const calculate = (timeseries) => {
        let data_grouped = groupByMonth(timeseries)
        const maxValue = Math.max(...data_grouped.map(item => item.value !== undefined ? item.value : 0))
        const new_unit = adaptative_unit(maxValue, unit)
        let series_data = getSeries(data_grouped, new_unit)
        let dj_series = getDjuDjrSeries(dju, djr, data_grouped).filter(item => item !== null)
        let dj_min_value = Math.max(...dj_series.map(item => Math.max(...item.data))) / calculate_maximum_value(series_data) * calculate_minimum_value(series_data)
        let dj_series_with_unavailable_data = dj_series.map(item => {
            item.data = item.data.map(value => value === null ? unavailable_data : value)
            return item
        })
        let series = series_data.length > 0 ? [...series_data, ...dj_series_with_unavailable_data] : []
        setSeries(series)
        setNewUnit(new_unit)
        setDjMinValue(dj_min_value)
        setDataGrouped(data_grouped)
        setIsDone(true)
        return
    }

    useEffect(() => {
        if (!isDone && !isLoading && !isLoadingDJ && timeseries) {
            if (timeseries.length > 0) {
                calculate(timeseries)
            } else if (timeseries.length === 0) {
                setIsDone(true)
            }
        }
    }, [isLoading, isLoadingDJ, timeseries, isDone, dju, djr])



    let yAxis = [
        {
            type: 'value',
            name: 'Consommation (' + new_unit + ')',
            nameLocation: 'middle',
            nameGap: 30,
            nameTextStyle: {
                fontSize: 10,
            },
            axisLabel: {
                fontSize: 10,
                formatter: function (value) {
                    return Math.round(value * 100) / 100
                }
            },
            min: dj_min_value < 0 ? 'dataMin' : 0,
            max: dj_min_value < 0 ? 'dataMax' : null,
        },
        {
            type: 'value',
            name: t('sites.histogram.dj'),
            nameLocation: 'middle',
            nameGap: 30,
            nameTextStyle: {
                fontSize: 10,
            },
            splitLine: {
                show: false
            },
            axisLabel: {
                fontSize: 10,
                formatter: function (value, index) {
                    if (value >= 0) {
                        return Math.round(value)
                    }
                }
            },
            min: dj_min_value,
            max: 'dataMax',
        }
    ]

    return (
        <ChartHorizontalBar
            series={series}
            new_unit={new_unit}
            isLoading={!isDone}
            title={props.title}
            isData={isDone && series.length > 0}
            x_data={data_grouped.map(item => t(`months.${item.month - 1}`) + ' ' + item.year)}
            data_total={data_grouped.map(item => convert_to_right_unit(Math.round(item.value), unit, new_unit, unavailable_data))}
            hideLegend={props.hideLegend}
            yAxis={yAxis}
        />
    )
}

export default ChartDeviceHorizontalBarDJ;
